
  import React from 'react';
import {AppletIcon} from '@workday/canvas-kit-react/icon';
import {benefitsIcon} from '@workday/canvas-applet-icons-web';

const Example = () => (
  <>
    <AppletIcon icon={benefitsIcon} />
    <AppletIcon icon={benefitsIcon} color={AppletIcon.Colors.Pomegranate} />
    <AppletIcon icon={benefitsIcon} size={60} />
  </>
);

export default Example;
  Example.__RAW__ = "import React from 'react';\nimport {AppletIcon} from '@workday/canvas-kit-react/icon';\nimport {benefitsIcon} from '@workday/canvas-applet-icons-web';\n\nexport default () => (\n  <>\n    <AppletIcon icon={benefitsIcon} />\n    <AppletIcon icon={benefitsIcon} color={AppletIcon.Colors.Pomegranate} />\n    <AppletIcon icon={benefitsIcon} size={60} />\n  </>\n);\n";