import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import { benefitsIcon } from '@workday/canvas-applet-icons-web';
import Example from './examples/AppletIcon.tsx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const ExampleCodeBlock = makeShortcode("ExampleCodeBlock");
const SymbolDoc = makeShortcode("SymbolDoc");
const InternalContent = makeShortcode("InternalContent");
const SearchableIconList = makeShortcode("SearchableIconList");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Guidance" mdxType="TabPanel">
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <p>{`Applet Icons act as the brand or logo of a product, service or tool, they convey the core concept of
the products that they depict. Applet icons should be used to specifically convey entry points to
related products and services. They should never be used as standard icons as this can impact an
individual's understanding or trust of a product's entry point when misused.`}</p>
      <h2 {...{
        "id": "color"
      }}>{`Color`}</h2>
      <p>{`Applet icons can be themed to match the color of Workday customer branding (Blueberry by default).
Rather than specifying a specific color, a shade level (100-600) is assigned for each layer.
Depending on which color is selected, the icon will automatically update all layers to the various
shades of that color. Do not override any layers within an Applet icon to a specific color, as this
will break the theming support. For more information, check out the
`}<a parentName="p" {...{
          "href": "#tab=create-your-own&heading=layers"
        }}>{`Layers section`}</a>{` on the "Create Your Own" tab.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e18aef8b3b60c425423b49f711d7120b/07a9c/applet_icon_color.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "23.783783783783786%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of an Applet icons use of color.",
              "title": "Example of an Applet icons use of color.",
              "src": "/static/e18aef8b3b60c425423b49f711d7120b/50383/applet_icon_color.png",
              "srcSet": ["/static/e18aef8b3b60c425423b49f711d7120b/1efb2/applet_icon_color.png 370w", "/static/e18aef8b3b60c425423b49f711d7120b/50383/applet_icon_color.png 740w", "/static/e18aef8b3b60c425423b49f711d7120b/07a9c/applet_icon_color.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "labeling"
      }}>{`Labeling`}</h2>
      <p>{`Labels should always accompany Applet icons to reinforce and make clear to an individual which entry
point they are seeking out.`}</p>
      <h2 {...{
        "id": "sizing"
      }}>{`Sizing`}</h2>
      <p>{`Applet icons are made to a specific standard, changing the size of an icon can cause anti-aliasing
issues. Canvas does not recommend scaling applet icons. If for a specific reason you do need to
scale an icon we recommend following Canvas spacing rules resizing in increments of 8 pixels.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e8f09cc19c0a98dbd592c585e2e962e0/07a9c/applet_icon_sizing.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "25.675675675675674%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of resizing an Applet icon.",
              "title": "Example of resizing an Applet icon.",
              "src": "/static/e8f09cc19c0a98dbd592c585e2e962e0/50383/applet_icon_sizing.png",
              "srcSet": ["/static/e8f09cc19c0a98dbd592c585e2e962e0/1efb2/applet_icon_sizing.png 370w", "/static/e8f09cc19c0a98dbd592c585e2e962e0/50383/applet_icon_sizing.png 740w", "/static/e8f09cc19c0a98dbd592c585e2e962e0/07a9c/applet_icon_sizing.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "alignment"
      }}>{`Alignment`}</h2>
      <p>{`App icons should be center aligned vertically or horizontally depending on the situation. This
ensures that the icons look visually balanced when in close proximity to another asset. Text should
also be center aligned to an app icon.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/199b2b64f5d173032d3079cfb268cb14/07a9c/applet_icon_alignment.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "45.13513513513514%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of a Applet icon alignment.",
              "title": "Example of a Applet icon alignment.",
              "src": "/static/199b2b64f5d173032d3079cfb268cb14/50383/applet_icon_alignment.png",
              "srcSet": ["/static/199b2b64f5d173032d3079cfb268cb14/1efb2/applet_icon_alignment.png 370w", "/static/199b2b64f5d173032d3079cfb268cb14/50383/applet_icon_alignment.png 740w", "/static/199b2b64f5d173032d3079cfb268cb14/07a9c/applet_icon_alignment.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "web-examples"
      }}>{`Web Examples`}</h2>
      <p>{`The icons shipped in `}<inlineCode parentName="p">{`@workday/canvas-applet-icons-web`}</inlineCode>{` are just SVGs with some additional metadata.
If you'd like to easily use them in React, use the `}<inlineCode parentName="p">{`AppletIcon`}</inlineCode>{` component from
`}<inlineCode parentName="p">{`@workday/canvas-kit-react/icon`}</inlineCode>{`:`}</p>
      <ExampleCodeBlock code={Example} scope={{
        benefitsIcon
      }} showCode={true} mdxType="ExampleCodeBlock" />
      <SymbolDoc name="AppletIcon" hideHeading headingStart={2} mdxType="SymbolDoc" />
      <InternalContent mdxType="InternalContent">
        <h2 {...{
          "id": "ios-examples"
        }}>{`iOS Examples`}</h2>
        <p>{`Coming soon!`}</p>
        <h2 {...{
          "id": "android-examples"
        }}>{`Android Examples`}</h2>
        <p>{`Coming soon!`}</p>
      </InternalContent>
    </TabPanel>
    <TabPanel data-id="Gallery" mdxType="TabPanel">
      <SearchableIconList iconType="wd-applet-icons" mdxType="SearchableIconList" />
    </TabPanel>
    <TabPanel data-id="Create Your Own" mdxType="TabPanel">
      <h2 {...{
        "id": "create-your-own"
      }}>{`Create Your Own`}</h2>
      <p>{`Can’t find the icon you need in our library? Why not make your own! Follow the steps outlined here
to create your own icon to contribute back to the Canvas Design System. Before creating a new icon,
make sure you have checked over the `}<a parentName="p" {...{
          "href": "#tab=gallery"
        }}>{`Gallery`}</a>{` and searched for keywords to surface an
icon that may already exist.`}</p>
      <h2 {...{
        "id": "base-grid"
      }}>{`Base Grid`}</h2>
      <p>{`Applet icons are built on a base grid, using a grid will help you draw your icon to Workdays brand
specific parameters, keeping things consistent. Canvas maintains a base grid of 92x92 px for Accent
icons.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f1ea8e28db90153bddbcae87bf732a10/07a9c/applet_icon_base_grid.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of a Applet icons base grid.",
              "title": "Example of a Applet icons base grid.",
              "src": "/static/f1ea8e28db90153bddbcae87bf732a10/50383/applet_icon_base_grid.png",
              "srcSet": ["/static/f1ea8e28db90153bddbcae87bf732a10/1efb2/applet_icon_base_grid.png 370w", "/static/f1ea8e28db90153bddbcae87bf732a10/50383/applet_icon_base_grid.png 740w", "/static/f1ea8e28db90153bddbcae87bf732a10/07a9c/applet_icon_base_grid.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h3 {...{
        "id": "safe-area"
      }}>{`Safe Area`}</h3>
      <p>{`The safe area ensures that the Applet icon remains fully visible when motion is applied to the Icon.
No part of the icon should extend past the surrounding white space of the safe area.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ea9c0178e028281e568932a26999c97d/07a9c/applet_icon_safe_area.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of a Applet icons safe area.",
              "title": "Example of a Applet icons safe area.",
              "src": "/static/ea9c0178e028281e568932a26999c97d/50383/applet_icon_safe_area.png",
              "srcSet": ["/static/ea9c0178e028281e568932a26999c97d/1efb2/applet_icon_safe_area.png 370w", "/static/ea9c0178e028281e568932a26999c97d/50383/applet_icon_safe_area.png 740w", "/static/ea9c0178e028281e568932a26999c97d/07a9c/applet_icon_safe_area.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h3 {...{
        "id": "underlying-grid"
      }}>{`Underlying Grid`}</h3>
      <p>{`The underlying grid can be used to support visual harmony and balance by unifying the geometric
proportions of and icons shapes.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3ed26779dba193535126a9c306790694/07a9c/applet_icon_underlying_grid.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of a Applet icons underlying grid.",
              "title": "Example of a Applet icons underlying grid.",
              "src": "/static/3ed26779dba193535126a9c306790694/50383/applet_icon_underlying_grid.png",
              "srcSet": ["/static/3ed26779dba193535126a9c306790694/1efb2/applet_icon_underlying_grid.png 370w", "/static/3ed26779dba193535126a9c306790694/50383/applet_icon_underlying_grid.png 740w", "/static/3ed26779dba193535126a9c306790694/07a9c/applet_icon_underlying_grid.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h3 {...{
        "id": "key-shapes"
      }}>{`Key Shapes`}</h3>
      <p>{`Using a key shape provides a consistent size for basic shapes and proportions across the entire App
icon suite. They help maintain a common visual style.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/174066c52ee0f1aba8b8f992f32fde20/07a9c/applet_icon_key_shape.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of a Applet icons key shapes.",
              "title": "Example of a Applet icons key shapes.",
              "src": "/static/174066c52ee0f1aba8b8f992f32fde20/50383/applet_icon_key_shape.png",
              "srcSet": ["/static/174066c52ee0f1aba8b8f992f32fde20/1efb2/applet_icon_key_shape.png 370w", "/static/174066c52ee0f1aba8b8f992f32fde20/50383/applet_icon_key_shape.png 740w", "/static/174066c52ee0f1aba8b8f992f32fde20/07a9c/applet_icon_key_shape.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><strong parentName="p">{`All Key Shapes`}</strong></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/cb68b2d899f4ede20886a4da59cef1cc/bd9eb/applet_icon_all_key_shape.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "27.297297297297295%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of Applet icons respective key shapes.",
              "title": "Example of Applet icons respective key shapes.",
              "src": "/static/cb68b2d899f4ede20886a4da59cef1cc/50383/applet_icon_all_key_shape.png",
              "srcSet": ["/static/cb68b2d899f4ede20886a4da59cef1cc/1efb2/applet_icon_all_key_shape.png 370w", "/static/cb68b2d899f4ede20886a4da59cef1cc/50383/applet_icon_all_key_shape.png 740w", "/static/cb68b2d899f4ede20886a4da59cef1cc/bd9eb/applet_icon_all_key_shape.png 1442w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h3 {...{
        "id": "background-circle"
      }}>{`Background Circle`}</h3>
      <p>{`All Applet icons are placed on a background circle to highlight and distinguish their place within
Workdays UI and set them apart from system and accent icons. This element should never be removed
from the Applet icon.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/9b7fb75f3ee6aeed15a6eb0a910ab6cb/07a9c/applet_icon_background_circle.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of an Applet icons background circle.",
              "title": "Example of an Applet icons background circle.",
              "src": "/static/9b7fb75f3ee6aeed15a6eb0a910ab6cb/50383/applet_icon_background_circle.png",
              "srcSet": ["/static/9b7fb75f3ee6aeed15a6eb0a910ab6cb/1efb2/applet_icon_background_circle.png 370w", "/static/9b7fb75f3ee6aeed15a6eb0a910ab6cb/50383/applet_icon_background_circle.png 740w", "/static/9b7fb75f3ee6aeed15a6eb0a910ab6cb/07a9c/applet_icon_background_circle.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "strokes"
      }}>{`Strokes`}</h2>
      <p>{`Maintain a 2px outer stroke on all shapes used to build an icon, this includes the use of circles.
An outer stroke allows the icon to remain consistent when a corner radius is applied later on.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fa29fbe8a923b93454371a989e7f8f25/07a9c/applet_icon_strokes.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of an Applet icons stroke weight.",
              "title": "Example of an Applet icons stroke weight.",
              "src": "/static/fa29fbe8a923b93454371a989e7f8f25/50383/applet_icon_strokes.png",
              "srcSet": ["/static/fa29fbe8a923b93454371a989e7f8f25/1efb2/applet_icon_strokes.png 370w", "/static/fa29fbe8a923b93454371a989e7f8f25/50383/applet_icon_strokes.png 740w", "/static/fa29fbe8a923b93454371a989e7f8f25/07a9c/applet_icon_strokes.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "corners"
      }}>{`Corners`}</h2>
      <p>{`A minimum corner radius of 1px should be applied to a shape, maintaining a consistent corner radius
on all shapes to ensure visual consistency across the entire Applet icon suite.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fb5c96dd7290c7a7ab9fc53364bb598a/07a9c/applet_icon_corners.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of an Applet icons corner radius.",
              "title": "Example of an Applet icons corner radius.",
              "src": "/static/fb5c96dd7290c7a7ab9fc53364bb598a/50383/applet_icon_corners.png",
              "srcSet": ["/static/fb5c96dd7290c7a7ab9fc53364bb598a/1efb2/applet_icon_corners.png 370w", "/static/fb5c96dd7290c7a7ab9fc53364bb598a/50383/applet_icon_corners.png 740w", "/static/fb5c96dd7290c7a7ab9fc53364bb598a/07a9c/applet_icon_corners.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "density"
      }}>{`Density`}</h2>
      <p>{`Canvas advise a minimum of 2px between each shape to ensure the icons can be accurately depicted on
screen, anything less may diminish the icons appearance.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8ea2f5b2f8de8facd3fda826fb1b8462/07a9c/applet_icon_density.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of an Applet icons density.",
              "title": "Example of an Applet icons density.",
              "src": "/static/8ea2f5b2f8de8facd3fda826fb1b8462/50383/applet_icon_density.png",
              "srcSet": ["/static/8ea2f5b2f8de8facd3fda826fb1b8462/1efb2/applet_icon_density.png 370w", "/static/8ea2f5b2f8de8facd3fda826fb1b8462/50383/applet_icon_density.png 740w", "/static/8ea2f5b2f8de8facd3fda826fb1b8462/07a9c/applet_icon_density.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "layers"
      }}>{`Layers`}</h2>
      <p>{`Layers are categorized by the colors they represent and are named to reference corresponding colors
ranges, for example ‘color-200’ references all shades of 200 on the Canvas Color palette. This
allows Workday customers to configure applet icons to match their brand color.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/913c33d0519a211b3524a361b9dc6f8e/07a9c/applet_icon_layers.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of an Applet icons layers.",
              "title": "Example of an Applet icons layers.",
              "src": "/static/913c33d0519a211b3524a361b9dc6f8e/50383/applet_icon_layers.png",
              "srcSet": ["/static/913c33d0519a211b3524a361b9dc6f8e/1efb2/applet_icon_layers.png 370w", "/static/913c33d0519a211b3524a361b9dc6f8e/50383/applet_icon_layers.png 740w", "/static/913c33d0519a211b3524a361b9dc6f8e/07a9c/applet_icon_layers.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h3 {...{
        "id": "grouping-and-motion"
      }}>{`Grouping and Motion`}</h3>
      <p>{`When layers are grouped they can be targeted with a number of predefined motions when named after
Canvas Motion tokens. This is done to add visual attitude or flair to an Applet icon. Not all layers
require motion - it is best used sparingly to create a subtle and memorable icon. Reference the
table below when using motion layer names. Motion tokens can also be combined together. An example
of this is 'animate-float-rotate-left', a combination of 'float' and 'rotate-left'.`}</p>
      <p><strong parentName="p">{`Motion Variables`}</strong></p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Variable Name`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Expected action`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`animate-float`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`Group moves up`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`animate-sink`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`Group moves down`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`animate-left`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`Group moves left`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`animate-right`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`Group moves right`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`animate-grow`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`Group increases size`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`animate-shrink`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`Group decreases size`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`animate-rotate-left`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`Group rotates 15° left`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`animate-rotate-right`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`Group rotates 15° right`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`animate-spin`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`Group rotates 180° clockwise`}</inlineCode></td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "naming"
      }}>{`Naming`}</h3>
      <p>{`Applet icons are named after their related product or key function, they are named as such to
distinguish their place in Workdays product suite.`}</p>
      <InternalContent mdxType="InternalContent">
        <h2 {...{
          "id": "contribution"
        }}>{`Contribution`}</h2>
        <p>{`Once you're ready to submit your icon, go check out the information supplied in the Contributing
Back To Canvas section of this site to help you make your submission.`}</p>
        <p>{`Thank you for making a contribution!`}</p>
        <p><a parentName="p" {...{
            "href": "https://www.figma.com/file/XgVeczZk3faPPOwLT3sDYn/Applet-Icon-Contribution-Workspace"
          }}>{`Follow this link`}</a>{`
to access the guided Applet Icon Workspace file in Figma.`}</p>
      </InternalContent>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      